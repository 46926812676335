import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUser from "../../services/queries/useUser";
import useItem from "../../services/queries/useItem";
import toRibuan from "../../utils/toRibuan";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    DialogContent,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
    DaftarItemListInvoice,
    InputPembayaranBody,
    InputPembayaranResponse,
    ParamsGetItem,
} from "../../constants/types";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface IModalRevertInvoiceProps {
    refetchLaporan: () => void;
    refetchListInvoice: () => void;
    refetchProdukTerjual: () => void;
    refetchMutasiStok: () => void;
    refetchGrafikPenjualan: () => void;
    refetchLaporanLabaRugi: () => void;
    diskon?: number;
    totalHarga: number;
    totalTagihan: number;
    uangDibayar: number;
    kembalian: number;
    item?: DaftarItemListInvoice[];
    statusItem: "idle" | "error" | "loading" | "success";
    invoiceId: number;
    closeModalRevertInvoice: () => void;
    isOpenModalRevertInvoice: boolean;
}

export const ModalRevertInvoice = (props: IModalRevertInvoiceProps) => {
    const theme = useTheme();
    const moment = require("moment");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        isShow: 0,
        search: "",
        kategori: "",
    };

    const [paramsItem, setParamsItem] = useState<ParamsGetItem>(initialParams);
    const [totalHargaSeluruhItem, setTotalHargaSeluruhItem] = useState(0);
    const { refetch: refetchItem } = useItem(paramsItem);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const {
        handleReset,
        isPrintStruk,
        resetPrint,
        setFetchingItems,
        handlingDonePembayaran,
        // diskon,
        // grandTotal,
        // bayar,
        // kembalian,
    } = useTransaksi();

    const current = new Date();
    current.getSeconds();

    const handleHitungTotalHargaSeluruhItem = useCallback(() => {
        let total = 0;
        if (props.item) {
            props.item.forEach((list) => {
                total +=
                    list.hargaSatuan *
                        (list.jumlahItem - list.jumlahItem * 2) ?? 0;
            });
            setTotalHargaSeluruhItem(total);
            return;
        }
    }, [props.item]);

    useEffect(() => {
        handleHitungTotalHargaSeluruhItem();
    }, [props.item, handleHitungTotalHargaSeluruhItem]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const inputPembayaran = async () => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran",
                {
                    ukmId: ukmIdUser && ukmIdUser[0],
                    jenisItem:
                        props.item &&
                        props.item.map((li) => ({
                            itemId: li.idItem,
                            namaItem: li.namaItem,
                            skuItem: li.skuItem,
                            jumlahItem: li.jumlahItem - li.jumlahItem * 2,
                            hargaSatuan: li.hargaSatuan,
                            hargaTotal: li.hargaTotal * -1,
                            kodeFaktur: li.faktur.map((fa) => ({
                                idFaktur: fa.idFaktur,
                                kodeFaktur: null,
                                jumlahItem: fa.jumlah * -1,
                            })),
                        })),
                    userInput: `${user?.userId}`,
                    sales: null,
                    totalHarga: totalHargaSeluruhItem,
                    diskon: Number(props.diskon) * -1,
                    totalPembayaran:
                        totalHargaSeluruhItem - Number(props.diskon) * -1,
                    uangYangDibayar: 0,
                    kembalian: 0,
                    isReverted: 1,
                    invoiceId: props.invoiceId,
                    metodeId: 0,
                } as InputPembayaranBody,
            );
            if (data.code === 200) {
                props.refetchLaporan();
                props.refetchListInvoice();
                props.refetchProdukTerjual();
                props.refetchMutasiStok();
                props.refetchGrafikPenjualan();
                props.refetchLaporanLabaRugi();
                refetchItem();
                props.closeModalRevertInvoice();
                Swal.fire({
                    title: "Pembatalan berhasil!",
                    text: "Pembayaran telah berhasil dibatalkan",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });

                setFetchingItems();
                handleReset();
                setIsButtonLoading(false);
            }
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    props.closeModalRevertInvoice();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.data}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                } else {
                    props.closeModalRevertInvoice();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        },
        [props],
    );

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isPrintStruk) {
            inputPembayaran();
            resetPrint();
        }
    }, [inputPembayaran, isPrintStruk, resetPrint]);

    // if (status === "loading") {
    //     return (
    //         <Box position="relative">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    return (
        <Box>
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                open={
                    props.isOpenModalRevertInvoice &&
                    props.statusItem === "success"
                }
                onClose={props.closeModalRevertInvoice}
                scroll="paper"
                PaperProps={{
                    sx: {
                        borderRadius: "10px",
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={props.closeModalRevertInvoice}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Batalkan Pembayaran
                        </Typography>
                    </Stack>
                </DialogTitle>
                <Stack
                    paddingLeft={3}
                    paddingRight={4}
                    paddingY={2}
                    borderBottom="1px solid rgba(0, 0, 0, 0.12)"
                    spacing={2}
                >
                    <Grid container>
                        <Grid item xs={5} sm={3} md={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Tanggal
                            </Typography>
                            <Typography variant="subtitle2" color="black">
                                {now}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Nama Kasir
                            </Typography>
                            <Typography variant="subtitle2" color="black">
                                {user?.namaLengkap}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <DialogContent sx={{ maxHeight: "40vh", paddingBottom: 2 }}>
                    <Stack direction="column" spacing={1}>
                        {props.item ? (
                            props.item.map((rows, idx) => (
                                <Box key={String(idx)}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Box width="50%">
                                            <Box>
                                                <Typography
                                                    variant="subtitle1"
                                                    fontWeight={600}
                                                    color="black"
                                                >
                                                    {rows.namaItem}
                                                </Typography>
                                                <Typography>
                                                    Rp.{" "}
                                                    {toRibuan(rows.hargaSatuan)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box display="flex">
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={600}
                                                color="black"
                                            >
                                                {rows.jumlahItem -
                                                    rows.jumlahItem * 2}
                                                x
                                            </Typography>
                                        </Box>
                                        <Stack
                                            direction="row"
                                            width="30%"
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Typography
                                                display="flex"
                                                variant="subtitle1"
                                                fontWeight={600}
                                                color="black"
                                                textAlign="end"
                                            >
                                                Rp.{" "}
                                                {toRibuan(
                                                    rows.hargaSatuan *
                                                        (rows.jumlahItem -
                                                            rows.jumlahItem *
                                                                2),
                                                )}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="h6">
                                Barang tidak ditemukan
                            </Typography>
                        )}
                    </Stack>
                    <Box
                        marginTop={2}
                        padding={2}
                        border={1}
                        borderColor="#d4d4d4"
                        borderRadius={2}
                    >
                        <Typography
                            marginBottom={1}
                            variant="subtitle1"
                            color="#000000"
                            fontWeight="bold"
                        >
                            Ringkasan Pembayaran
                        </Typography>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography variant="subtitle1" color="#404040">
                                    Subtotal Harga
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX={1}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {toRibuan(props.totalHarga * -1)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            marginTop={1}
                            justifyContent="space-between"
                        >
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography variant="subtitle1" color="#404040">
                                    Diskon
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    bgcolor="#FFF5EC"
                                    paddingX={1}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        color="#FB923C"
                                        fontWeight="bold"
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        color="#FB923C"
                                        fontWeight="bold"
                                    >
                                        {toRibuan(
                                            Number(props.diskon) * -1 ?? 0,
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            marginTop={1}
                            justifyContent="space-between"
                        >
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography variant="subtitle1" color="#404040">
                                    Total Tagihan
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    bgcolor="#E8F6ED"
                                    paddingX={1}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        color="#45A779"
                                        fontWeight="bold"
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        color="#45A779"
                                        fontWeight="bold"
                                    >
                                        {toRibuan(props.totalTagihan * -1)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <Grid
                    container
                    justifyContent="flex-end"
                    sx={{ borderTop: "1px solid #d4d4d4" }}
                >
                    <Grid item xs={12}>
                        <Box marginTop={3} marginBottom={4} marginX={3}>
                            <Stack
                                direction="column"
                                display="flex"
                                justifyContent="space-between"
                                marginBottom={1}
                                spacing={1}
                            >
                                <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Total
                                    </Typography>
                                    <Box
                                        bgcolor="#E8F6ED"
                                        borderRadius={1}
                                        paddingX={2}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                            color="#45A779"
                                        >
                                            Rp.{" "}
                                            {toRibuan(props.totalTagihan * -1)}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="start"
                                startIcon={<DeleteIcon />}
                                variant="contained"
                                fullWidth
                                size="large"
                                color="error"
                                onClick={() => {
                                    inputPembayaran();
                                    handlingDonePembayaran();
                                }}
                            >
                                Batalkan Pembayaran
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
        </Box>
    );
};

export default ModalRevertInvoice;
