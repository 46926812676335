import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUser from "../../services/queries/useUser";
import * as yup from "yup";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { DefaultResponse, ErrorFieldResponse } from "../../constants/types";
import {
    Stack,
    Typography,
    Grid,
    TextField,
    useMediaQuery,
    Button,
    Stepper,
    Step,
    StepLabel,
    StepContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTransaksi } from "../../context/transaksiContext";
import { apiUrl } from "../../constants/common";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUkm from "../../services/queries/useUkm";
import { Download, UploadFile } from "@mui/icons-material";

interface IModalImportProps {
    namaToko?: string;
    isPro?: number;
}

interface IValuesImport {
    ukmId: string;
    berkas: File | null;
}

const schema = yup.object({
    ukmId: yup.string(),
    berkas: yup.mixed(),
});

export default function UploadStok({ namaToko, isPro }: IModalImportProps) {
    const theme = useTheme();
    const { setFetchingItems } = useTransaksi();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();
    const initialParamsAllUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: allUkm } = useUkm(initialParamsAllUkm);
    const [selectUkm, setSelectUkm] = useState<any>();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);

    const initialValues: IValuesImport = useMemo(
        () => ({
            ukmId: selectUkm ?? Number(ukmIdUser && ukmIdUser[0]),
            berkas: null,
        }),
        [selectUkm, ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<IValuesImport>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    function handleDrop(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 1) {
            Swal.fire({
                title: "Hanya bisa mengunggah satu berkas",
                position: "top-end",
                showConfirmButton: false,
                icon: "error",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                showCloseButton: true,
                customClass: {
                    container: "my-swal",
                },
            });
        } else {
            const file = files[0];
            if (file.size < 512000) {
                setValue("berkas", file);
                clearErrors("berkas");
            } else {
                setError("berkas", {
                    type: "manual",
                    message: "Ukuran berkas harus kurang dari 500KB",
                });
                Swal.fire({
                    title: "Ukuran berkas harus kurang dari 500KB",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        }

        setIsDragOver(false);
    }

    const onSubmit = async (values: IValuesImport) => {
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("ukmId", values.ukmId);
            formData.append(
                "file",
                values.berkas ? (values.berkas as any) : "",
            );
            const { data } = await axios.post<DefaultResponse>(
                `/api/importitem`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Stok berhasil di-import",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            setFetchingItems();
            reset(initialValues);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    Swal.fire({
                        title: `${serverError.response.data?.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
            setIsButtonLoading(false);
        }
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return allUkm?.content.find((li) => li.id === ukmId);
        }
        return allUkm?.content[0];
    }, [allUkm?.content, ukmId]);

    useEffect(() => {
        if (Number(localStorage.getItem(`user${user?.userId}`))) {
            setSelectUkm(Number(localStorage.getItem(`user${user?.userId}`)));
        }
    }, [user?.userId]);

    return (
        <>
            <Typography color="#2d312f" fontSize={32} fontWeight="bold" py={2}>
                Unggah Stok
            </Typography>
            <Stepper orientation="vertical">
                <Step active>
                    <StepLabel>Unduh Format Pengisian</StepLabel>
                    <StepContent>
                        <Typography color="#464e4b">
                            Unduh dan lengkapi format pengisian yang telah
                            disediakan untuk mengunggah stok Anda.
                        </Typography>
                        <a href={`${apiUrl}api/importitem`}>
                            <Button
                                variant="outlined"
                                size="large"
                                startIcon={<Download />}
                                sx={{ marginY: 1 }}
                            >
                                Unduh Template
                            </Button>
                        </a>
                    </StepContent>
                </Step>
                <Step active>
                    <StepLabel>Unggah Berkas</StepLabel>
                    <StepContent>
                        <Typography color="#464e4b">
                            Unggah berkas yang telah dilengkapi.
                        </Typography>
                        <Grid container alignItems="center" marginY={2}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                    backgroundColor: isDragOver
                                        ? "#bfd9d4"
                                        : "#e9f2f0",
                                    padding: "2rem 1rem",
                                    width: "100%",
                                    border: "1px dashed #a8b4af",
                                    borderRadius: "8px",
                                    transition: "background-color 0.3s",
                                }}
                                onDrop={handleDrop}
                                onDragOver={(event) => {
                                    event.preventDefault();
                                    setIsDragOver(true);
                                }}
                                onDragLeave={() => setIsDragOver(false)}
                            >
                                <UploadFile
                                    sx={{ color: "#7d7d7d", fontSize: "3rem" }}
                                />
                                <Typography color="#464e4b">
                                    Drag and drop files or browse document
                                </Typography>
                                <Controller
                                    name="berkas"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="berkas"
                                            type="file"
                                            variant="outlined"
                                            inputProps={{
                                                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                                            }}
                                            error={Boolean(errors.berkas)}
                                            helperText={
                                                errors.berkas
                                                    ? errors.berkas.message
                                                    : ""
                                            }
                                            {...field}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target.files[0].size <
                                                    512000
                                                ) {
                                                    setValue(
                                                        "berkas",
                                                        event.target.files[0],
                                                    );
                                                    clearErrors("berkas");
                                                } else {
                                                    event.target.value = "";
                                                    setError("berkas", {
                                                        type: "manual",
                                                        message:
                                                            "Ukuran berkas harus kurang dari 500KB",
                                                    });
                                                }
                                            }}
                                            value={undefined}
                                            sx={{
                                                width: "0.1px",
                                                height: "0.1px",
                                                opacity: 0,
                                                overflow: "hidden",
                                                position: "absolute",
                                                zIndex: -1,
                                                "& + label": {
                                                    display: "inline-block",
                                                    color: "#7c8883",
                                                    padding: "0.5rem 1rem",
                                                    border: "1px solid #7c8883",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                },
                                                "& + label:focus": {
                                                    outline:
                                                        "2px solid #7c8883",
                                                },
                                                "& + label:hover": {
                                                    outline:
                                                        "2px solid #7c8883",
                                                },
                                            }}
                                        />
                                    )}
                                />
                                <label htmlFor="berkas">Choose file</label>
                                {getValues("berkas") && (
                                    <Typography color="#464e4b" fontSize={14}>
                                        {getValues("berkas")?.name}
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                        <Stack direction="row" justifyContent="flex-end">
                            <LoadingButton
                                loading={isButtonLoading}
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                type="submit"
                                fullWidth={!isDesktopScreen}
                            >
                                Unggah Stok
                            </LoadingButton>
                        </Stack>
                    </StepContent>
                </Step>
            </Stepper>

            {ukmData && (
                <ModalAlertPro
                    isOpenModalAlertPro={isOpenModalAlertPro}
                    closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                    dataUkm={{ id: ukmData?.id, nama: ukmData?.namaToko }}
                />
            )}
        </>
    );
}
