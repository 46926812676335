import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    Card,
    FormGroup,
    FormControlLabel,
    Switch,
    Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PrintBarcode from "../PrintBarcode/PrintBarcode";
import { pdf } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { usePrintBarcodeModal } from "../../context/printBarcodeModal";
import { Close } from "@mui/icons-material";

interface IModalPrintBarcodeProps {}

const ModalPrintBarcode = (props: IModalPrintBarcodeProps) => {
    var Barcode = require("react-barcode");
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [jumlahCetak, setJumlahCetak] = useState<string>("1");
    const {
        isOpenModalPrintBarcode,
        sku,
        closeModalPrintBarcode,
        namaBarang,
        hargaBarang,
        isLabel,
        handleChangeLabel,
    } = usePrintBarcodeModal();
    const [printMode, setPrintMode] = useState<string>("A4");
    const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

    const handlingPrintBarcode = () => {
        setIsLoadingButton(true);
        var obj = pdf(
            <PrintBarcode
                jumlahCetak={Number(jumlahCetak)}
                valueBarcode={sku ?? ""}
                namaBarang={String(namaBarang)}
                hargaBarang={String(hargaBarang)}
                sku={String(sku)}
                printMode={printMode}
            />,
        ).toBlob();
        obj.then(function (blob) {
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank");
            setIsLoadingButton(false);
            closeModalPrintBarcode();
            setJumlahCetak("1");
        });
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalPrintBarcode}
            onClose={() => {
                closeModalPrintBarcode();
                setJumlahCetak("1");
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography color="#2d312f" fontSize={32} fontWeight="bold">
                        Cetak Barcode
                    </Typography>
                    <IconButton
                        onClick={() => {
                            closeModalPrintBarcode();
                            setJumlahCetak("1");
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid
                    container
                    alignItems="center"
                    marginBottom={2}
                    paddingY={1}
                >
                    <Typography>Mode Print Barcode</Typography>

                    <FormControl fullWidth sx={{ marginTop: "10px" }}>
                        <Select
                            labelId="print-mode-select-label"
                            id="print-mode-select"
                            value={printMode}
                            onChange={(e) => setPrintMode(e.target.value)}
                        >
                            <MenuItem value={"A4"}>Kertas A4</MenuItem>
                            <MenuItem value={"Label"}>Label no. 109</MenuItem>
                            <MenuItem value={"50mm x 20mm"}>
                                50mm x 20mm
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Typography color="#464e4b">
                    Anda dapat melakukan cetak barcode berdasarkan SKU barang.
                </Typography>
                <Stack direction="column" spacing={0.4} marginBottom={2}>
                    <Typography color="#464e4b">
                        Pastikan kertas yang digunakan untuk mencetak adalah{" "}
                        <b>
                            {printMode === "Label"
                                ? "Label no. 109"
                                : printMode === "50mm x 20mm"
                                ? "Kertas 50mm x 20mm"
                                : "Kertas A4"}
                        </b>
                        .
                    </Typography>
                </Stack>
                {printMode === "Label" ? (
                    <Card
                        variant="outlined"
                        sx={{ padding: 1, width: "fit-content" }}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            alignItems="center"
                        >
                            <Barcode
                                value={sku ?? ""}
                                height={70}
                                fontSize={16}
                            />
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            marginTop="-4px"
                            paddingX={1}
                        >
                            <Typography fontFamily="monospace">
                                {namaBarang?.toUpperCase()}
                            </Typography>
                            <Typography fontFamily="monospace">
                                {hargaBarang}
                            </Typography>
                        </Stack>
                    </Card>
                ) : printMode === "50mm x 20mm" ? (
                    <Card
                        variant="outlined"
                        sx={{
                            padding: 1,
                            width: "fit-content",
                            transform: "rotate(90deg)",
                            margin: "100px -40px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            alignItems="center"
                        >
                            <Barcode
                                value={sku ?? ""}
                                width={2}
                                height={50}
                                fontSize={12}
                                displayValue={false}
                            />
                            <Typography
                                fontSize="12px"
                                fontWeight="bold"
                                fontFamily="monospace"
                                marginTop="2px"
                            >
                                {namaBarang?.toUpperCase()}
                            </Typography>
                            <Typography fontFamily="monospace">
                                {hargaBarang}
                            </Typography>
                        </Stack>
                    </Card>
                ) : (
                    <Card
                        variant="outlined"
                        sx={{ padding: 1, width: "fit-content" }}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            alignItems="center"
                        >
                            <Barcode
                                value={sku ?? ""}
                                height={70}
                                fontSize={16}
                            />
                            <Typography
                                fontSize="25px"
                                fontWeight="bold"
                                fontFamily="monospace"
                                marginTop="-10px"
                            >
                                {hargaBarang}
                            </Typography>
                            <Typography fontFamily="monospace">
                                {namaBarang?.toUpperCase()}
                            </Typography>
                        </Stack>
                    </Card>
                )}
                <Grid item marginTop={2} marginBottom={6}>
                    <Typography fontWeight={500} color="#464e4b">
                        Jumlah yang ingin dicetak (maks.{" "}
                        {printMode === "Label" ? "55" : "150"})
                    </Typography>
                    <TextField
                        placeholder="Masukkan jumlah yang ingin dicetak"
                        fullWidth
                        value={jumlahCetak}
                        type="number"
                        onChange={(e) => setJumlahCetak(e.target.value)}
                    />
                    {printMode === "Label" ? (
                        Number(jumlahCetak) > 55 ? (
                            <Typography
                                marginTop={1}
                                variant="body2"
                                color="#dc2626"
                                fontWeight={600}
                            >
                                Jumlah cetak melebihi maksimal
                            </Typography>
                        ) : null
                    ) : (
                        Number(jumlahCetak) > 150 && (
                            <Typography
                                marginTop={1}
                                variant="body2"
                                color="#dc2626"
                                fontWeight={600}
                            >
                                Jumlah cetak melebihi maksimal
                            </Typography>
                        )
                    )}
                </Grid>
                <LoadingButton
                    loading={isLoadingButton}
                    loadingPosition="center"
                    variant="contained"
                    fullWidth={isPhoneScreen}
                    onClick={() => handlingPrintBarcode()}
                    disabled={
                        (printMode === "Label"
                            ? Number(jumlahCetak) > 55
                            : Number(jumlahCetak) > 150) ||
                        Number(jumlahCetak) <= 0
                    }
                >
                    Cetak
                </LoadingButton>
            </DialogContent>
        </Dialog>
    );
};

export default ModalPrintBarcode;
