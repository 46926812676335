import React from "react";
import {
    StyleSheet,
    Font,
    Page,
    Document,
    Image,
    View,
    Text,
} from "@react-pdf/renderer";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v29/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf",
});

Font.register({
    family: "Ubuntu-Mono",
    src: "https://fonts.gstatic.com/s/ubuntumono/v15/KFOjCneDtsqEr0keqCMhbBc9.ttf",
});

const styles = StyleSheet.create({
    page: {
        flexWrap: "wrap",
        flexDirection: "row",
        backgroundColor: "#FFF",
        paddingVertical: 10,
    },
    wrapper: {
        width: "auto",
        height: 110,
        display: "flex",
        flexDirection: "column",
        marginHorizontal: 10,
        marginVertical: 10,
        padding: 10,
        border: "1px solid #bdbdbd",
        borderRadius: "6px",
    },
    barcode: {
        width: "auto",
        height: 50,
    },
    harga: {
        fontSize: 14,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
    },
    nama: {
        fontSize: 10,
        fontFamily: "Open-Sans",
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    sku: {
        fontSize: 8,
        fontFamily: "Open-Sans",
        textAlign: "center",
        fontWeight: "bold",
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
    },
});

const styles2 = StyleSheet.create({
    page: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        backgroundColor: "#FFF",
        paddingVertical: "2mm",
        paddingLeft: "5mm",
    },
    wrapper: {
        height: "10mm",
        width: "35mm",
        display: "flex",
        flexDirection: "column",
        marginHorizontal: "2.4mm",
        marginVertical: "2.3mm",
        padding: 2,
    },
    barcode: {
        width: "auto",
    },
    harga: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        fontWeight: "bold",
    },
    nama: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    sku: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
    },
    textContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
    },
    textParent: {
        padding: 2,
    },
});

const styles3 = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#FFF",
        paddingVertical: "0mm",
        paddingRight: "0mm",
    },
    wrapper: {
        width: "auto",
        display: "flex",
        padding: "0px",
        transform: "rotate(90deg)",
        margin: "auto 0",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
    barcode: {
        width: "115px",
        minHeight: "30px",
        maxHeight: "35px",
    },
    nama: {
        fontSize: 6,
        fontFamily: "Open-Sans",
        fontWeight: "bold",
        textTransform: "uppercase",
        writingMode: "vertical-rl",
        marginTop: "0",
        textAlign: "center",
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "110px",
        maxWidth: "115px",
    },
    harga: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
    },
});

const widthInMm = 20;
const heightInMm = 50;
const widthInPoints = widthInMm * 2.835;
const heightInPoints = heightInMm * 2.835;

interface IPrintBarcodeProps {
    jumlahCetak: number;
    valueBarcode: string;
    namaBarang: string;
    hargaBarang: string;
    sku: string;
    printMode: string;
}

const PrintBarcode = ({
    jumlahCetak,
    valueBarcode,
    namaBarang,
    hargaBarang,
    sku,
    printMode,
}: IPrintBarcodeProps) => {
    var JsBarcode = require("jsbarcode");

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, `${valueBarcode}`, {
        height: 70,
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    return (
        <Document>
            {printMode === "Label" ? (
                <Page size="A4" style={styles2.page}>
                    {Array.from(Array(jumlahCetak)).map((li, idx) => (
                        <View key={String(idx)} style={styles2.wrapper} wrap={false}>
                            <Image style={styles2.barcode} src={barcode} />
                            <View style={styles2.textContainer}>
                                <View>
                                    <Text style={styles2.sku}>{sku}</Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: 2,
                                }}
                            >
                                <View>
                                    <Text style={styles2.nama}>{namaBarang}</Text>
                                </View>
                                <View>
                                    <Text style={styles2.harga}>{hargaBarang}</Text>
                                </View>
                            </View>
                        </View>
                    ))}
                </Page>
            ) : printMode === "A4" ? (
                <Page size="A4" style={styles.page}>
                    {Array.from(Array(jumlahCetak)).map((li, idx) => (
                        <View key={String(idx)} style={styles.wrapper} wrap={false}>
                            <Image style={styles.barcode} src={barcode} />
                            <View style={styles.textContainer}>
                                <Text style={styles.sku}>{sku}</Text>
                                <Text style={styles.harga}>{hargaBarang}</Text>
                                <Text style={styles.nama}>{namaBarang}</Text>
                            </View>
                        </View>
                    ))}
                </Page>
            ) : (
                Array.from(Array(jumlahCetak)).map((li, idx) => (
                    <Page size={{ width: widthInPoints, height: heightInPoints }} style={styles3.page} key={String(idx)}>
                        <View style={styles3.wrapper} wrap={false}>
                            <View>
                                <Image style={styles3.barcode} src={barcode} />
                                <View style={styles3.textContainer}>
                                    <Text style={styles3.nama}>{namaBarang}</Text>
                                </View>
                                <View style={styles3.textContainer}>
                                    <Text style={styles3.harga}>{hargaBarang}</Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                ))
            )}
        </Document>
    );
};

export default PrintBarcode;
