import React, { useState } from "react";
import Box from "@mui/material/Box";
import TabelBarang from "../../components/TabelBarang/TabelBarang";
import ModalImport from "../../components/ModalImport/ModalImport";
import ModalKatalog from "../../components/ModalKatalog/ModalKatalog";
import ModalKategori from "../../components/ModalKategori/ModalKategori";
import TokoTidakAktif from "../../components/TokoTidakAktif/TokoTidakAktif";
import ModalPrintBarcode from "../../components/ModalPrintBarcode/ModalPrintBarcode";
import ModalEditKategori from "../../components/ModalEditKategori/ModalEditKategori";
import ModalDeleteKategori from "../../components/ModalDeleteKategori/ModalDeleteKategori";
import { useAuth } from "../../context/authContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import useTime from "../../services/queries/useTime";
import moment from "moment";
import "moment/locale/id";
import { Tab, Tabs } from "@mui/material";
import MutasiStok from "../../components/MutasiStok/MutasiStok";
import UploadStok from "../../components/UploadStok/UploadStok";
import AturKategori from "../../components/AturKategori/AturKategori";
import AturSubKategori from "../../components/AturSubKategori/AturSubKategori";

moment.locales();
moment.locale("id");

interface IStokBarangProps {}

const StokBarang = (props: IStokBarangProps) => {
    const { ukmIdUser } = useAuth();
    const initialParamsKategori = {
        ukmId: Number(ukmIdUser && ukmIdUser[0]),
        size: 500,
        page: 1,
        search: "",
    };
    const { refetch: refetchKategori } = useKategori(initialParamsKategori);

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: time } = useTime();
    const { data: user } = useUser();

    const [tab, setTab] = useState(0);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const isSudahJatuhTempo = moment(time?.datetime).isAfter(
        ukmData?.pembayaranBerikutnya,
    );

    if (user?.aksesStok === 0 || user?.aksesStok === null) {
        return <TokoTidakAktif label="Anda tidak memiliki akses halaman ini" />;
    }

    if (
        ukmData &&
        (ukmData?.isActive === 0 ||
            ukmData?.isActive === null ||
            ukmData?.lewatTempoPro === 1 ||
            isSudahJatuhTempo)
    ) {
        return <TokoTidakAktif namaToko={ukmData.namaToko} />;
    }

    return (
        <Box>
            <Box>
                <Tabs
                    value={tab}
                    onChange={(_, newValue) => setTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label="Daftar Stok" />
                    <Tab label="Mutasi Stok" />
                    <Tab label="Unggah Stok" />
                    <Tab label="Atur Kategori" />
                    <Tab label="Atur Sub Kategori" />
                    <Tab label="Buat Katalog" />
                </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
                <TabelBarang />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
                <MutasiStok />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={2}>
                <UploadStok />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={3}>
                <AturKategori />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={4}>
                <AturSubKategori />
            </CustomTabPanel>
            <ModalKategori refetch={refetchKategori} />
            <ModalDeleteKategori refetch={refetchKategori} />
            <ModalEditKategori refetch={refetchKategori} />
            <ModalKatalog namaUkm={ukmData?.namaToko} />
            <ModalImport namaToko={ukmData?.namaToko} isPro={ukmData?.isPro} />
            <ModalPrintBarcode />
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export default StokBarang;
