// import { useQuery } from "react-query";
// import { GetDetailInvoiceResponse } from "../../constants/types";
// import axios from "../axios";

// const handleRequest = async (id: number) => {
//     const { data } = await axios.get<GetDetailInvoiceResponse>(
//         `/api/laporan/invoice/${id}`,
//     );
//     return data.data;
// };

// export default function useDetailInvoice(id: number) {
//     return useQuery<GetDetailInvoiceResponse["data"]>(
//         ["detailInvoice", id],
//         () => handleRequest(id),
//         { enabled: !!id },
//     );
// }

import { useQuery, useQueryClient } from "react-query";
import { GetDetailInvoiceResponse, ParamsGetKategoriListInvoice } from "../../constants/types";
import axios from "../axios";
const queryString = require("query-string");

const handleRequest = async (id: number, params: ParamsGetKategoriListInvoice) => {
    const apiUrl = queryString.stringifyUrl({
        url: `/api/laporan/invoice/${id}`,
        query: {
            grupProduk: params.grupProduk,
        },
    });
    const { data } = await axios.get<GetDetailInvoiceResponse>(apiUrl);
    return data.data;
};

export default function useDetailInvoice(id: number, params: ParamsGetKategoriListInvoice = { grupProduk: 0 }) {
    return useQuery<GetDetailInvoiceResponse["data"]>(
        ["detailInvoice", id, params],
        () => handleRequest(id, params),
        { enabled: !!id },
    );
}

