import { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import Spinner from "../Spinner/Spinner";
import useUser from "../../services/queries/useUser";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useKategori from "../../services/queries/useKategori";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { Controller, useForm } from "react-hook-form";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { useHapusKategoriModal } from "../../context/hapusKategoriModalContext";
import {
    AddKategoriBody,
    AddKategoriResponse,
    ErrorFieldResponse,
    ParamsGetKategori,
} from "../../constants/types";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import { Add, Close, Search } from "@mui/icons-material";

const schema = yup
    .object({
        namaKategori: yup.string().required("Kolom wajib diisi"),
    })
    .required();

export default function AturSubKategori() {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { isOpenModalKategori, closeModalKategori } = useKategoriModal();
    const { openModalEditKategori } = useEditKategoriModal();
    const { openModalHapusKategori } = useHapusKategoriModal();
    const { ukmIdUser } = useAuth();
    const { status: statusUser } = useUser();
    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        search: "",
    };
    const [paramsKategori, setParamsKategori] =
        useState<ParamsGetKategori>(initialParams);
    const {
        data: dataKategori,
        refetch: refetchKategori,
        isLoading: isLoadingKategori,
    } = useKategori(paramsKategori);

    const [addCategoryOpen, setAddCategoryOpen] = useState(false);

    const [search, setSearch] = useState("");
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        refetchKategori();
    }, [isOpenModalKategori, refetchKategori]);

    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama Sub Kategori",
            flex: 1,
            minWidth: 170,
        },
        {
            field: "namaKategori",
            headerName: "Nama Kategori",
            flex: 1,
            minWidth: 170,
        },
        {
            field: "jumlahProduk",
            headerName: "Jumlah Produk",
            flex: 1,
            minWidth: 170,
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 130,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalEditKategori(
                                    Number(params.id),
                                    params.row.nama,
                                );
                                closeModalKategori();
                            }}
                            size="small"
                            color="buttonyellow"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <EditIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                openModalHapusKategori(
                                    params.id,
                                    params.row.nama,
                                );
                                closeModalKategori();
                            }}
                            size="small"
                            color="buttonred"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const handleOnPagination = (page: number) => {
        setParamsKategori((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsKategori((prev) => ({ ...prev, size }));
    };

    function handleSearch() {
        setParamsKategori((prev) => ({
            ...prev,
            search: search,
            page: 1,
        }));
    }

    const initialValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            namaKategori: "",
        }),
        [ukmIdUser],
    );

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
    } = useForm<AddKategoriBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: AddKategoriBody) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<AddKategoriResponse>(
                `/api/kategori`,
                values,
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Kategori berhasil ditambahkan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                refetchKategori();
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(true);
            setAddCategoryOpen(false);
            reset(initialValues);
        } catch (err) {
            if (defaultAxios.isAxiosError(err)) {
                const serverError = err as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    console.log(serverError.response?.data?.errors);
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as any, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
                setIsButtonLoading(false);
                setIsSuccessSubmit(false);
            }
            handleErrorResponse(err);
            setIsSuccessSubmit(false);
            setIsButtonLoading(false);
        }
    };

    const handleErrorResponse = useCallback((error) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            console.log(serverError.response);
            if (serverError && serverError.response) {
                console.log(`serverError`, serverError);
                if (serverError.response.data.data.errors) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            } else {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `Terjadi kesalahan! Silahkan coba lagi.`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        }
    }, []);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isSuccessSubmit === true) {
            refetchKategori();
        }
        setIsSuccessSubmit(false);
    }, [isSuccessSubmit, refetchKategori]);

    useEffect(() => {
        if (isOpenModalKategori) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalKategori, reset]);

    const isLoading = statusUser === "loading";

    if (isLoading) {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    color="#2d312f"
                    fontSize={32}
                    fontWeight="bold"
                    py={2}
                >
                    Atur Kategori
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setAddCategoryOpen(true)}
                >
                    Tambah Kategori
                </Button>
            </Stack>
            <TextField
                placeholder="Cari kategori"
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(event.target.value);
                }}
                onKeyDown={(e) => {
                    const element = e.target as HTMLInputElement;
                    if (e.key === "Enter") {
                        setParamsKategori((prev) => ({
                            ...prev,
                            search: element.value,
                            page: 1,
                        }));
                    }
                }}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        backgroundColor: "white",
                    },
                }}
            />
            <Box
                sx={{
                    width: "100%",
                    marginTop: 2,
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    autoHeight
                    paginationMode="server"
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExportIcon />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    rows={dataKategori?.content ?? []}
                    columns={columns}
                    disableColumnMenu
                    disableSelectionOnClick
                    rowsPerPageOptions={[10]}
                    onPageSizeChange={handleOnSizeChange}
                    onPageChange={handleOnPagination}
                    rowCount={dataKategori?.totalElements ?? 0}
                    page={paramsKategori.page - 1}
                    pageSize={paramsKategori.size}
                    density="standard"
                    loading={isLoadingKategori}
                />
            </Box>

            <Dialog open={addCategoryOpen} fullWidth maxWidth="md">
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                    }}
                >
                    <Typography color="#2D312F" fontSize={32} fontWeight={700}>
                        Tambah Kategori
                    </Typography>
                    <IconButton onClick={() => setAddCategoryOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Typography color="#464e4b" fontWeight={500} mb={1}>
                                Nama Kategori
                            </Typography>
                            <Controller
                                name="namaKategori"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        autoFocus
                                        id="namaKategori"
                                        placeholder="Masukkan nama kategori yang hendak ditambahkan"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaKategori)}
                                        helperText={
                                            errors.namaKategori
                                                ? errors.namaKategori.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: "Nama Kategori required",
                                }}
                            />
                        </div>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            gap={1}
                            mt={2}
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={() => setAddCategoryOpen(false)}
                            >
                                Kembali
                            </Button>
                            <LoadingButton
                                loading={isButtonLoading}
                                variant="contained"
                                startIcon={<AddIcon />}
                                type="submit"
                            >
                                Tambahkan
                            </LoadingButton>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
