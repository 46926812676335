import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowBackIosNew } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    OutlinedInput,
    Stack,
    Switch,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTransaksi } from "../../context/transaksiContext";
import { IOptions, ParamsGetItem } from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useUsersByUkm from "../../services/queries/useUsersByUkm";
import { useAuth } from "../../context/authContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";
import ButtonNumKalkulator from "../ButtonNumKalkulator/ButtonNumKalkulator";
import ButtonKalkulator from "../ButtonKalkulator/ButtonKalkulator";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import KonfirmasiBayar from "../KonfirmasiBayar/KonfirmasiBayar";
import useItem from "../../services/queries/useItem";
import { Controller } from "react-hook-form";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import id from "date-fns/locale/id";
import "react-datepicker/dist/react-datepicker.css";
import "./Bayar.css";

registerLocale("id", id);
setDefaultLocale("id");

type ToggleDiskon = "nominal" | "persentase";

export default function Bayar() {
    const {
        cart,
        closeModalBayar,
        totalHarga,
        openModalKonfirmasiBayar,
        isDonePembayaran,
        handlingResetPembayaran,
        resetCart,
        checked,
        setChecked,
        confirmPaymentMode,
        keteranganBeli,
        resetKeteranganBeli,
        newCustomerForm,
    } = useTransaksi();

    const { ukmIdUser } = useAuth();
    const initialParamsUser = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 100,
        page: 1,
        search: "",
    };
    const { data: usersByUkm, isLoading: isLoadingUsersByUkm } =
        useUsersByUkm(initialParamsUser);

    const initialParamsItem = {
        ukmId: undefined,
        size: 1000,
        isShow: 0,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsItem, setParamsItem] =
        useState<ParamsGetItem>(initialParamsItem);
    const { data: item, refetch: refetchItem } = useItem(paramsItem);

    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("lg"));

    const [diskon, setDiskon] = useState<number>();
    const [persentaseDiskon, setPersentaseDiskon] = useState<number | null>();
    const [pajak, setPajak] = useState<number>();
    const [persentasePajak, setPersentasePajak] = useState<number | null>();
    const [tagihan, setTagihan] = useState<number>();
    const [uangDibayar, setUangDibayar] = useState<number>();
    const [kembalian, setKembalian] = useState<number>(0);
    const [sales, setSales] = useState<IOptions | null>(null);
    const [inputBayar, setInputBayar] = useState<any>("");
    const [inputDiskon, setInputDiskon] = useState<any | null>("");
    const [toggleDiskon, setToggleDiskon] = useState<ToggleDiskon>("nominal");
    const [displayDiskon, setDisplayDiskon] = useState<number>(0);
    const [debtChecked, setDebtChecked] = useState(false);
    const [debtDueDate, setDebtDueDate] = useState<Date | null>(null);
    const [newCustomerChecked, setNewCustomerChecked] = useState(false);
    const [totalTagihan, setTotalTagihan] = useState<number>(totalHarga);
    const [isDiskonFocused, setIsDiskonFocused] = useState(false);
    const [displayPajak, setDisplayPajak] = useState<number>(0);

    const handlingRincianPembayaran = (
        persentaseDiskon: number | null,
        diskon: number | null,
        persentasePajak: number | null,
        pajak: number | null,
        tagihan: number,
        uangYangDibayar: number,
        kembalian: number,
        sales: IOptions | null,
    ) => {
        if (diskon && diskon > 0) {
            setDiskon(diskon);
        } else {
            setDiskon(0);
        }
        if (persentaseDiskon !== null) {
            setPersentaseDiskon(persentaseDiskon);
        }
        if (pajak && pajak > 0) {
            setPajak(pajak);
        } else {
            setPajak(0);
        }
        if (persentasePajak !== null) {
            setPersentasePajak(persentasePajak);
        }
        setTagihan(tagihan);
        setUangDibayar(uangYangDibayar);
        setKembalian(kembalian);
        setSales(sales);
    };

    const mappedUsersByUkm = usersByUkm?.content.map((li) => ({
        id: li.userId,
        nama: li.namaLengkap,
    }));

    const defaultMaskOptions = {
        prefix: "",
        suffix: "",
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ".",
        allowDecimal: true,
        decimalSymbol: ",",
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 7, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    };

    const focusDiskon = () => setIsDiskonFocused(true);
    const unfocusDiskon = () => setIsDiskonFocused(false);

    const { data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);

    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const onQuickActionClick = (digit: number) => {
        let newDisplay = Number(inputBayar) + digit;
        setInputBayar(newDisplay);
    };

    const onClearButtonClick = () => {
        if (isDiskonFocused) {
            setInputDiskon("");
        } else {
            setInputBayar("");
        }
    };

    const pilihNominal = () => {
        setToggleDiskon("nominal");
        setInputDiskon("");
    };

    const pilihPersentase = () => {
        setToggleDiskon("persentase");
        setInputDiskon("");
    };

    const onDelButtonClick = () => {
        if (isDiskonFocused) {
            let value = inputDiskon ?? 0;
            if (value === 0) {
                setInputDiskon("");
            } else {
                let newDisplay = Math.floor(value / 10);
                setInputDiskon(newDisplay);
            }
        } else {
            let value = inputBayar ?? 0;
            if (value === 0) {
                setInputBayar("");
            } else {
                let newDisplay = Math.floor(value / 10);
                setInputBayar(newDisplay);
            }
        }
    };

    const onDigitButtonClick = (digit: number) => {
        if (isDiskonFocused) {
            let newDisplay = String(inputDiskon);

            if (
                (inputDiskon === 0 && digit === 0) ||
                String(inputDiskon).length > 12
            ) {
                return;
            }

            if (inputDiskon !== 0) {
                newDisplay = newDisplay + digit.toString();
            } else {
                newDisplay = digit.toString();
            }
            setInputDiskon(newDisplay);
        } else {
            let newDisplay = String(inputBayar);

            if (
                (inputBayar === 0 && digit === 0) ||
                String(inputBayar).length > 12
            ) {
                return;
            }

            if (inputBayar !== 0) {
                newDisplay = newDisplay + digit.toString();
            } else {
                newDisplay = digit.toString();
            }
            setInputBayar(newDisplay);
        }
    };

    const onEnterButtonClick = () => {
        // hitungBayar(Number(display));
        // setDisplay("");
    };

    const onZeroButtonClick = () => {
        if (isDiskonFocused) {
            let value = inputDiskon ?? 0;
            let newDisplay = value * 10;
            setInputDiskon(newDisplay);
        } else {
            let value = inputBayar ?? 0;
            let newDisplay = value * 10;
            setInputBayar(newDisplay);
        }
    };

    const onTripleZeroButtonClick = () => {
        if (isDiskonFocused) {
            let value = inputDiskon ?? 0;
            let newDisplay = value * 1000;
            setInputDiskon(newDisplay);
        } else {
            let value = inputBayar ?? 0;
            let newDisplay = value * 1000;
            setInputBayar(newDisplay);
        }
    };

    const handleResetRincianPembayaran = useCallback(() => {
        setInputBayar("");
        setInputDiskon("");
        setDisplayDiskon(0);
        setTotalTagihan(0);
        setKembalian(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputBayar, inputDiskon, displayDiskon, totalTagihan, kembalian]);

    const handleRincianPembayaran = useCallback(
        (
            inputPembayaran: number,
            toggleDiskon: "nominal" | "persentase",
            inputDiskon: number | null,
            pajak: number | null,
        ) => {
            if (pajak) {
                const potongan = pajak / 100;
                const totalPajak = Math.ceil(totalHarga * potongan);
                const hasilTotalTagihan = totalHarga + totalPajak;
                setDisplayPajak(totalPajak);
                setTotalTagihan(hasilTotalTagihan);
                setKembalian(inputBayar - hasilTotalTagihan);
                if (toggleDiskon === "nominal") {
                    const hasilTotalTagihan =
                        totalHarga + totalPajak - (inputDiskon ?? 0);
                    setDisplayDiskon(inputDiskon ?? 0);
                    setTotalTagihan(hasilTotalTagihan);
                    setKembalian(inputPembayaran - hasilTotalTagihan);
                } else if (toggleDiskon === "persentase") {
                    const potongan = (inputDiskon ?? 0) / 100;
                    const totalDiskon = totalHarga - totalHarga * potongan;
                    const totalAkhir = totalHarga - totalDiskon;
                    const hasilTotalTagihan =
                        totalHarga + totalPajak - totalAkhir;
                    setDisplayDiskon(totalAkhir);
                    setTotalTagihan(hasilTotalTagihan);
                    setKembalian(inputPembayaran - hasilTotalTagihan);
                }
            } else if (toggleDiskon === "nominal") {
                const hasilTotalTagihan = totalHarga - (inputDiskon ?? 0);
                setDisplayDiskon(inputDiskon ?? 0);
                setTotalTagihan(totalHarga - (inputDiskon ?? 0));
                setKembalian(inputPembayaran - hasilTotalTagihan);
            } else if (toggleDiskon === "persentase") {
                const potongan = (inputDiskon ?? 0) / 100;
                const totalDiskon = totalHarga - totalHarga * potongan;
                const totalAkhir = totalHarga - totalDiskon;
                const hasilTotalTagihan = totalHarga - totalAkhir;
                setDisplayDiskon(totalAkhir);
                setTotalTagihan(hasilTotalTagihan);
                setKembalian(inputPembayaran - hasilTotalTagihan);
            }
            if (inputPembayaran <= 0) {
                setKembalian(0);
            }
        },
        [inputBayar, totalHarga],
    );

    useEffect(() => {
        if (ukmData && ukmData.pajak) {
            const potongan = ukmData.pajak / 100;
            const totalPajak = Math.ceil(totalHarga * potongan);
            const hasilTotalTagihan = totalHarga + totalPajak;
            setDisplayPajak(totalPajak);
            setTotalTagihan(hasilTotalTagihan);
            setKembalian(inputBayar - hasilTotalTagihan);
        }
    }, [inputBayar, totalHarga, ukmData]);

    useEffect(() => {
        if (ukmData && (inputBayar !== null || inputDiskon !== null)) {
            handleRincianPembayaran(
                inputBayar,
                toggleDiskon,
                inputDiskon.toString().replace(",", "."),
                ukmData?.pajak,
            );
        }
    }, [
        handleRincianPembayaran,
        inputBayar,
        inputDiskon,
        toggleDiskon,
        ukmData,
    ]);

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
    });

    useEffect(() => {
        if (isDonePembayaran === true) {
            handleResetRincianPembayaran();
            handlingResetPembayaran();
            resetCart();
        }
    }, [
        handleResetRincianPembayaran,
        handlingResetPembayaran,
        isDonePembayaran,
        resetCart,
    ]);

    return confirmPaymentMode ? (
        <KonfirmasiBayar
            kasir={String(user?.namaLengkap)}
            sales={sales}
            diskon={diskon}
            persentaseDiskon={persentaseDiskon}
            pajak={pajak}
            persentasePajak={persentasePajak}
            totalTagihan={Number(tagihan)}
            uangDibayar={Number(uangDibayar)}
            kembalian={Number(kembalian)}
            refetch={refetchItem}
            keteranganBeli={keteranganBeli}
            resetKeteranganBeli={resetKeteranganBeli}
        />
    ) : (
        <>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    overflowY: "scroll",
                }}
            >
                <Stack direction="row" py={1} gap={1}>
                    <Button
                        size="small"
                        sx={{ padding: "0.25rem" }}
                        onClick={() => closeModalBayar()}
                    >
                        <ArrowBackIosNew />
                    </Button>
                    <Typography
                        py={1}
                        color="#464e4b"
                        fontSize={24}
                        fontWeight={600}
                    >
                        Detail Transaksi
                    </Typography>
                </Stack>
                <Stack spacing={3} pr={2}>
                    <div>
                        <Stack direction="row" justifyContent="space-between">
                            <div>
                                <Typography
                                    color="#2d312f"
                                    fontSize={24}
                                    fontWeight={600}
                                >
                                    Piutang
                                </Typography>
                                <Typography color="#464e4b">
                                    Catat transaksi sebagai piutang (membutuhkan
                                    informasi pelanggan)
                                </Typography>
                            </div>
                            <Switch
                                size="small"
                                checked={debtChecked}
                                onChange={(e) =>
                                    setDebtChecked(e.target.checked)
                                }
                                inputProps={{
                                    "aria-label": "Piutang",
                                }}
                                sx={{ alignSelf: "center" }}
                            />
                        </Stack>
                        {debtChecked && (
                            <DatePicker
                                className="date-picker"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Pilih Tanggal Jatuh Tempo"
                                onFocus={(e) => e.target.blur()}
                                selected={debtDueDate}
                                onChange={(date) => setDebtDueDate(date)}
                            />
                        )}
                    </div>

                    <div>
                        <Typography
                            color="#2d312f"
                            fontSize={24}
                            fontWeight={600}
                        >
                            Set Diskon Harga
                        </Typography>
                        <Typography color="#464e4b">
                            Atur potongan harga berdasarkan nilai nominal
                            langsung atau nilai persen.
                        </Typography>
                        <Grid container spacing={0} gap={1} mt={1}>
                            <Grid item xs={12} md={2.5}>
                                <Button
                                    disabled={
                                        user &&
                                        !user.akses.transaksi.includes(12)
                                    }
                                    size="medium"
                                    fullWidth
                                    variant={
                                        toggleDiskon === "nominal"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    onClick={() => pilihNominal()}
                                >
                                    Nominal
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2.5}>
                                <Button
                                    disabled={
                                        user &&
                                        !user.akses.transaksi.includes(12)
                                    }
                                    fullWidth
                                    variant={
                                        toggleDiskon === "persentase"
                                            ? "contained"
                                            : "outlined"
                                    }
                                    onClick={() => pilihPersentase()}
                                >
                                    Persentase
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6.5}>
                                <MaskedInput
                                    disabled={
                                        user &&
                                        !user.akses.transaksi.includes(12)
                                    }
                                    value={inputDiskon}
                                    mask={currencyMask}
                                    placeholder="0"
                                    id="pay"
                                    onFocus={focusDiskon}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setInputDiskon(
                                            event.target.value.replace(
                                                /\./g,
                                                "",
                                            ) ?? "",
                                        );
                                    }}
                                    render={(ref, props) => (
                                        <TextField
                                            inputRef={ref}
                                            {...props}
                                            error={
                                                toggleDiskon === "persentase" &&
                                                inputDiskon > 100
                                            }
                                            helperText={
                                                toggleDiskon === "persentase" &&
                                                inputDiskon > 100
                                                    ? "Diskon harus kurang dari 100 persen"
                                                    : " "
                                            }
                                            fullWidth
                                            InputProps={{
                                                startAdornment:
                                                    toggleDiskon ===
                                                    "nominal" ? (
                                                        <InputAdornment position="start">
                                                            <Typography fontWeight="bold">
                                                                Rp.
                                                            </Typography>
                                                        </InputAdornment>
                                                    ) : (
                                                        ""
                                                    ),
                                                endAdornment:
                                                    toggleDiskon ===
                                                    "persentase" ? (
                                                        <InputAdornment position="end">
                                                            <Typography fontWeight="bold">
                                                                %
                                                            </Typography>
                                                        </InputAdornment>
                                                    ) : (
                                                        ""
                                                    ),
                                            }}
                                            inputProps={{
                                                inputMode: "numeric",
                                                style: {
                                                    fontWeight: "bold",
                                                    textAlign: "right",
                                                    height: "18px",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <div>
                        <Stack direction="row" justifyContent="space-between">
                            <div>
                                <Typography
                                    color="#2d312f"
                                    fontSize={24}
                                    fontWeight={600}
                                >
                                    Pelanggan
                                </Typography>
                                <Typography color="#464e4b">
                                    Atur nama pelanggan (opsional)
                                </Typography>
                            </div>
                            <Stack
                                direction="row"
                                alignSelf="center"
                                justifyContent="space-between"
                                gap={0.5}
                            >
                                <Typography color="#464e4b">
                                    Pelanggan Baru
                                </Typography>
                                <Switch
                                    checked={newCustomerChecked}
                                    onChange={(e) => {
                                        setNewCustomerChecked(e.target.checked);
                                        if (!e.target.checked) {
                                            newCustomerForm.reset();
                                        }
                                    }}
                                    size="small"
                                    inputProps={{
                                        "aria-label": "Pelanggan Baru",
                                    }}
                                />
                            </Stack>
                        </Stack>
                        {newCustomerChecked ? (
                            <Grid container gap={0.5} mt={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        color="#464e4b"
                                        fontWeight={500}
                                    >
                                        Nama Lengkap
                                    </Typography>
                                    <Controller
                                        name="nama"
                                        control={newCustomerForm.control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    newCustomerForm.formState
                                                        .errors.nama,
                                                )}
                                            >
                                                <OutlinedInput
                                                    id="nama"
                                                    placeholder="Contoh: Budi Perdana"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {newCustomerForm.formState
                                                        .errors.nama
                                                        ? newCustomerForm
                                                              .formState.errors
                                                              .nama.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        color="#464e4b"
                                        fontWeight={500}
                                    >
                                        Nomor Telepon
                                    </Typography>
                                    <Controller
                                        name="noTelp"
                                        control={newCustomerForm.control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    newCustomerForm.formState
                                                        .errors.noTelp,
                                                )}
                                            >
                                                <OutlinedInput
                                                    id="noTelp"
                                                    placeholder="Contoh: 081234567890"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {newCustomerForm.formState
                                                        .errors.noTelp
                                                        ? newCustomerForm
                                                              .formState.errors
                                                              .noTelp.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        color="#464e4b"
                                        fontWeight={500}
                                    >
                                        Alamat (opsional)
                                    </Typography>
                                    <Controller
                                        name="alamat"
                                        control={newCustomerForm.control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    newCustomerForm.formState
                                                        .errors.alamat,
                                                )}
                                            >
                                                <OutlinedInput
                                                    id="alamat"
                                                    placeholder="Contoh: Jalan Merdeka Raya No. 45"
                                                    multiline
                                                    minRows={3}
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {newCustomerForm.formState
                                                        .errors.alamat
                                                        ? newCustomerForm
                                                              .formState.errors
                                                              .alamat.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Autocomplete
                                disablePortal
                                options={[
                                    {
                                        label: "The Shawshank Redemption",
                                        year: 1994,
                                    },
                                    { label: "The Godfather", year: 1972 },
                                ]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Nama Pelanggan"
                                    />
                                )}
                                sx={{ marginTop: "1rem" }}
                            />
                        )}
                    </div>

                    <div>
                        <div>
                            <Typography
                                color="#2d312f"
                                fontSize={24}
                                fontWeight={600}
                            >
                                Sales
                            </Typography>
                            <Typography color="#464e4b">
                                Atur nama sales transaksi (opsional)
                            </Typography>
                        </div>
                        <Autocomplete
                            disabled={
                                user && !user.akses.transaksi.includes(12)
                            }
                            loading={isLoadingUsersByUkm}
                            disablePortal
                            options={mappedUsersByUkm ?? []}
                            getOptionLabel={(option) => option.nama}
                            isOptionEqualToValue={(option, value) =>
                                String(option.id) === String(value.id)
                            }
                            value={sales}
                            onChange={(_event, newSales: IOptions | null) => {
                                setSales(newSales);
                            }}
                            sx={{ marginTop: 2 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Nama Sales" />
                            )}
                        />
                    </div>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    borderLeft: isPhoneScreen
                        ? undefined
                        : "1px solid rgba(0, 0, 0, 0.12)",
                    borderRight: isPhoneScreen
                        ? undefined
                        : "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                <Stack gap={2} p={2}>
                    <Box
                        gridTemplateRows="1fr 1fr 4fr"
                        sx={{
                            display: { xs: "block", md: "grid" },
                            height: "calc(100vh - 6.5rem)",
                            rowGap: 1,
                        }}
                    >
                        <NumberFormat
                            value={inputBayar}
                            inputMode="numeric"
                            customInput={TextField}
                            thousandSeparator="."
                            decimalSeparator=","
                            autoFocus={isDesktopScreen}
                            fullWidth
                            placeholder="0"
                            allowNegative={false}
                            onFocus={unfocusDiskon}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setInputBayar(
                                    Number(
                                        event.target.value.replace(/\./g, ""),
                                    ),
                                );
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography
                                            variant="h4"
                                            fontWeight="bold"
                                        >
                                            Rp.
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                inputMode: "numeric",
                                style: {
                                    fontSize: 40,
                                    fontWeight: "bold",
                                    textAlign: "right",
                                },
                            }}
                        />
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr 1fr 1fr"
                            gap={1}
                        >
                            <Button
                                disabled={isDiskonFocused}
                                variant="outlined"
                                onClick={() => setInputBayar(totalTagihan)}
                            >
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: 14,
                                            md: "subtitle1.fontSize",
                                        },
                                    }}
                                    fontSize={16}
                                >
                                    PAS
                                </Typography>
                            </Button>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(100000)}
                            >
                                100.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(50000)}
                            >
                                50.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(20000)}
                            >
                                20.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(10000)}
                            >
                                10.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(5000)}
                            >
                                5.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(2000)}
                            >
                                2.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                disabled={isDiskonFocused}
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(1000)}
                            >
                                1.000
                            </ButtonKalkulator>
                        </Box>

                        {isLaptopScreen ? (
                            <Box
                                display="grid"
                                gridTemplateColumns="1fr 1fr 1fr 1fr"
                                gap={1}
                            >
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(1)}
                                >
                                    1
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(2)}
                                >
                                    2
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(3)}
                                >
                                    3
                                </ButtonNumKalkulator>
                                <ButtonKalkulator
                                    disabled={false}
                                    buttonVariant="contained"
                                    onClick={onDelButtonClick}
                                >
                                    DEL
                                </ButtonKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(4)}
                                >
                                    4
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(5)}
                                >
                                    5
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(6)}
                                >
                                    6
                                </ButtonNumKalkulator>
                                <ButtonKalkulator
                                    disabled={false}
                                    buttonVariant="contained"
                                    onClick={onClearButtonClick}
                                >
                                    C
                                </ButtonKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(7)}
                                >
                                    7
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(8)}
                                >
                                    8
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(9)}
                                >
                                    9
                                </ButtonNumKalkulator>
                                <Button
                                    variant="contained"
                                    className="is-enter"
                                    onClick={onEnterButtonClick}
                                    sx={{
                                        backgroundColor: "primary",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "subtitle1.fontSize",
                                                md: "h6.fontSize",
                                            },
                                        }}
                                    >
                                        ENTER
                                    </Typography>
                                </Button>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={onZeroButtonClick}
                                >
                                    0
                                </ButtonNumKalkulator>
                                <Button
                                    variant="outlined"
                                    className="is-000"
                                    onClick={onTripleZeroButtonClick}
                                >
                                    <Typography variant="h6">000</Typography>
                                </Button>
                            </Box>
                        ) : undefined}
                    </Box>
                    <Stack direction="row-reverse" gap={2} alignItems="center">
                        <Button
                            disabled={
                                inputBayar === 0 ||
                                inputBayar < totalTagihan ||
                                (toggleDiskon === "persentase" &&
                                    inputDiskon > 100)
                            }
                            variant="contained"
                            onClick={() => {
                                const cartZeroFaktur = cart.filter(
                                    (li) =>
                                        li.detailBundling.length === 0 &&
                                        li.faktur.length === 0,
                                );

                                let countBundling = 0;
                                cart.forEach((li) =>
                                    li.detailBundling.forEach((db) => {
                                        if (db.newFaktur) {
                                            return countBundling++;
                                        }
                                    }),
                                );
                                let count = 0;
                                cart.forEach((li) =>
                                    li.detailBundling.forEach((db) => {
                                        return count++;
                                    }),
                                );

                                const isNotEqual = count !== countBundling;
                                const notApproved =
                                    checked &&
                                    (cartZeroFaktur.length > 0 || isNotEqual);
                                if (notApproved) {
                                    Swal.fire({
                                        title: "Pilih faktur terlebih dahulu",
                                        position: "top-end",
                                        showConfirmButton: false,
                                        icon: "error",
                                        toast: true,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        showCloseButton: true,
                                        customClass: {
                                            container: "my-swal",
                                        },
                                    });
                                    return;
                                }
                                openModalKonfirmasiBayar();
                                handlingRincianPembayaran(
                                    toggleDiskon === "persentase"
                                        ? inputDiskon
                                        : 0,
                                    displayDiskon,
                                    ukmData ? ukmData?.pajak : 0,
                                    displayPajak,
                                    totalTagihan,
                                    inputBayar,
                                    kembalian,
                                    sales,
                                );
                            }}
                            size="large"
                            fullWidth
                        >
                            <Typography>Bayar</Typography>
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                closeModalBayar();
                                setChecked(false);
                                handleResetRincianPembayaran();
                            }}
                            fullWidth
                        >
                            <Typography>Kembali</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Grid>
        </>
    );
}
