import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import toRupiah from "../../utils/toRupiah";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import ModalDetailInvoice from "../ModalDetailInvoice/ModalDetailInvoice";
import ModalRevertInvoice from "../ModalRevertInvoice/ModalRevertInvoice";
import Button from "@mui/material/Button";

interface ICardInvoiceProps {
    detailInvoiceId: number;
    noInvoice: string;
    tanggalInvoice: string;
    hargaPenjualan: number;
    isReverted: number;
    metode: string;
    refetchListInvoice: () => void;
}

const CardInvoice = ({
    detailInvoiceId,
    noInvoice,
    tanggalInvoice,
    hargaPenjualan,
    isReverted,
    metode,
    refetchListInvoice,
}: ICardInvoiceProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const [isOpenModalRevertInvoice, setIsOpenModalRevertInvoice] =
        useState(false);
    const [isOpenModalDetailInvoice, setIsOpenModalDetailInvoice] =
        useState(false);
    // const { data: detailInvoice, status: statusDetailInvoice } =
    //     useDetailInvoice(detailInvoiceId);

    const closeModalDetailInvoice = () => {
        setIsOpenModalDetailInvoice(false);
    };

    const [paramsKategori, setParamsKategori] = useState<{ grupProduk: 0 | 1 }>({ grupProduk: 0 });

    const { data: detailInvoice, status: statusDetailInvoice, refetch: refetchDetailInvoice } =
        useDetailInvoice(detailInvoiceId, paramsKategori);

    console.log(detailInvoice);

    return (
        <React.Fragment>
            <Card
                variant="outlined"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                    padding: isTabletScreen ? 1 : 2,
                }}
            >
                <Box>
                    <Typography fontWeight={400} color="#7c8883">
                        {tanggalInvoice}
                    </Typography>
                    <Typography fontSize={20} fontWeight={500}>{noInvoice}</Typography>
                    <Typography
                        marginTop={1}
                        fontWeight={500}
                        color="#464E4B"
                    >
                        {isReverted
                            ? "Pembayaran dibatalkan"
                            : hargaPenjualan < 0
                            ? "Laporan pembatalan"
                            : `${metode}: ${toRupiah(hargaPenjualan)}`}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsOpenModalDetailInvoice(true);
                        }}
                        size="small"
                        color="buttongreen"
                        disableElevation
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <VisibilityIcon
                            sx={{ width: "16px", height: "16px" }}
                        />
                    </Button>
                    {/* {hargaPenjualan > 0 && isReverted === 0 && (
                        <IconButton
                            onClick={() => {
                                setIsOpenModalRevertInvoice(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )} */}
                </Stack>
            </Card>
            {detailInvoice && (
                <ModalDetailInvoice
                    id={detailInvoiceId}
                    fullScreen={fullScreen}
                    isOpenModalDetailInvoice={isOpenModalDetailInvoice}
                    statusDetailInvoice={statusDetailInvoice}
                    closeModalDetailInvoice={closeModalDetailInvoice}
                    tanggalTransaksi={String(detailInvoice.tanggalTransaksi)}
                    nomorInvoice={String(detailInvoice.nomorInvoice)}
                    daftarItem={detailInvoice.daftarItem}
                    daftarKategori={detailInvoice.daftarKategori}
                    totalHarga={Number(detailInvoice.totalHarga)}
                    diskon={Number(detailInvoice.diskon)}
                    totalPembayaran={Number(detailInvoice.totalPembayaran)}
                    uangYangDibayar={Number(detailInvoice.uangYangDibayar)}
                    kembalian={Number(detailInvoice.kembalian)}
                    kasir={detailInvoice.kasir}
                    sales={detailInvoice.sales}
                    pajak={detailInvoice.pajak}
                    pajakPersen={detailInvoice.pajakPersen}
                    namaMetode={detailInvoice.metode}
                    keterangan={detailInvoice.keterangan}
                    encrypt={detailInvoice.encrypt}
                    namaPemegang={detailInvoice.metodeArr.namaPemegang}
                    nomorTujuan={detailInvoice.metodeArr.noTujuan}
                    qr={detailInvoice.metodeArr.gambar}
                    transaksiKe={detailInvoice.transaksiKe}
                    paramsKategori={paramsKategori}
                    setParamsKategori={setParamsKategori}
                    refetchDetailInvoice={refetchDetailInvoice}
                />
            )}
            <ModalRevertInvoice
                diskon={Number(detailInvoice?.diskon)}
                totalHarga={Number(detailInvoice?.totalHarga)}
                totalTagihan={Number(detailInvoice?.totalPembayaran)}
                uangDibayar={Number(detailInvoice?.uangYangDibayar)}
                kembalian={Number(detailInvoice?.kembalian)}
                refetchLaporan={() => {}}
                refetchListInvoice={refetchListInvoice}
                refetchProdukTerjual={() => {}}
                refetchMutasiStok={() => {}}
                refetchGrafikPenjualan={() => {}}
                refetchLaporanLabaRugi={() => {}}
                item={detailInvoice?.daftarItem}
                statusItem={statusDetailInvoice}
                invoiceId={detailInvoiceId}
                isOpenModalRevertInvoice={isOpenModalRevertInvoice}
                closeModalRevertInvoice={() =>
                    setIsOpenModalRevertInvoice(false)
                }
            />
        </React.Fragment>
    );
};

export default CardInvoice;
