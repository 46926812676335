import * as React from "react";
import Drawer from "@mui/material/Drawer";
import useUkm from "../services/queries/useUkm";
import useUser from "../services/queries/useUser";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AlertSnackbar from "../components/AlertSnackbar/AlertSnackbar";
import ModalKonfirmasiLogout from "../components/ModalKonfirmasiLogout/ModalKonfirmasiLogout";
import {
    Backdrop,
    Badge,
    Button,
    CircularProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useAlert } from "../context/alertContext";
import { ParamsGetUkm } from "../constants/types";
import { Link, useLocation, useHistory } from "react-router-dom";
import { getLocalUkmId } from "../utils/handleChangeUkmId";
import {
    AppBar,
    Avatar,
    Box,
    Chip,
    Container,
    IconButton,
    Link as MaterialLink,
    Stack,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useUkmIsDueExpired from "../services/queries/useUkmIsDueExpired";
import { WhatsApp } from "@mui/icons-material";
import useNoWaAdmin from "../services/queries/useNoWaAdmin";
import logoUloGif from "../assets/images/LoadingUlo.gif";
import ModalTrial from "../components/ModalTrial/ModalTrial";
import ModalAlertPro from "../components/ModalAlertPro/ModalAlertPro";
import moment from "moment";
import Countdown from "react-countdown";
import useTime from "../services/queries/useTime";
import { drawerWidth } from "../constants/common";
import Icon from "@mdi/react";
import { mdiFileChart, mdiPackageVariant } from "@mdi/js";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    children: React.ReactNode;
    // window?: () => Window;
}

interface CountdownProps {
    expiredTime: number;
}

const UnmemorizedCountdownComponent: React.FC<CountdownProps> = (props) => {
    return (
        <Countdown
            date={Date.now() + props.expiredTime - 4000}
            renderer={({ completed, formatted }) => {
                if (completed) {
                    // Render a complete state
                    return <span>00:00:00</span>;
                } else {
                    // Render a countdown
                    return (
                        <Typography
                            display="inline"
                            sx={{
                                fontWeight: "bold",
                                color: "rgba(69, 167, 121, 1)",
                                // border: "1px solid rgba(69, 167, 121, 1)",
                                // borderRadius: "4px",
                                // padding: "3px 9px",
                                // color: "#fff",
                                // backgroundColor: "rgba(69, 167, 121, 1)",
                            }}
                        >
                            {formatted.days} hari, {formatted.hours} jam
                        </Typography>
                    );
                }
            }}
        />
    );
};

const CountdownComponent = React.memo(UnmemorizedCountdownComponent);

export default function PageLayout(props: Props) {
    const { children } = props;
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const { data: user, isLoading: isLoadingUser } = useUser();
    const initialParamsUkmIsDueExpired = React.useMemo(
        () => ({
            search: "",
            size: 5,
            page: 1,
            isDueExpired: 1,
        }),
        [],
    );
    const { data: ukmIsDueExpired } = useUkmIsDueExpired(
        initialParamsUkmIsDueExpired,
    );
    const { isOpenAlert, handleCloseAlert, words } = useAlert();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isOpenModalTrial, setIsOpenModalTrial] = React.useState(false);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = React.useState(false);
    // const [openSubmenu, setOpenSubmenu] = React.useState(true);
    const thisYear = new Date().getFullYear();
    const { data: noWaAdmin } = useNoWaAdmin();
    const { data: time } = useTime();

    const location = useLocation();
    let history = useHistory();

    const openModalTrial = () => setIsOpenModalTrial(true);
    const closeModalTrial = () => setIsOpenModalTrial(false);

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] =
        React.useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm, isLoading: isLoadingUkm } = useUkm(paramsUkm);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukmId, ukm]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const dateExpiredTime = React.useMemo(
        () => moment(ukmData?.trialTempo),
        [ukmData?.trialTempo],
    );
    const dateNow = React.useMemo(() => moment(), []);
    const expiredTime = dateExpiredTime.diff(dateNow);

    React.useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    history.listen(() => {
        setMobileOpen(false);
    });

    const laptopDrawer = (
        // <div>
        <Stack flex={1} direction="column" alignItems="flex-start" marginY={2}>
            <Box width="100%">
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    marginBottom={2}
                >
                    <Link to="/pengaturan">
                        <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    <Box
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            position: "relative",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        <Box>
                                            <SettingsIcon
                                                sx={{
                                                    width: "24px",
                                                    height: "24px",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                        "translate(-50%, -50%)",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                }
                            >
                                {ukmData?.alamatToko === "-" ||
                                !!!ukmData?.jenisUsaha ||
                                !!!ukmData?.alamatToko ? (
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        variant="dot"
                                        sx={{
                                            // display:
                                            //     ukmData?.alamatToko === "-" ||
                                            //     !!!ukmData?.alamatToko
                                            //         ? undefined
                                            //         : "none",
                                            "& .MuiBadge-badge": {
                                                backgroundColor: "#fa4c46",
                                                color: "#fa4c46",
                                                boxShadow: `0 0 0 2px #fff`,
                                                "&::after": {
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "50%",
                                                    animation:
                                                        "ripple 1.2s infinite ease-in-out",
                                                    border: "1px solid currentColor",
                                                    content: '""',
                                                },
                                            },
                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                backgroundColor: "primary.main",
                                                width: 64,
                                                height: 64,
                                            }}
                                            alt={user?.namaLengkap}
                                            src={
                                                user?.gambar ??
                                                user?.namaLengkap
                                            }
                                        />
                                    </Badge>
                                ) : (
                                    <Avatar
                                        sx={{
                                            backgroundColor: "primary.main",
                                            width: 64,
                                            height: 64,
                                        }}
                                        alt={user?.namaLengkap}
                                        src={user?.gambar ?? user?.namaLengkap}
                                    />
                                )}
                            </Badge>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    {user?.namaLengkap}
                                </Typography>
                                <Stack spacing={1} alignItems="center">
                                    <Typography
                                        textAlign="center"
                                        variant="caption"
                                        component="div"
                                    >
                                        {isLoadingUkm || isLoadingUser ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{ mt: 2 }}
                                            />
                                        ) : (
                                            ukmData?.namaToko
                                        )}
                                    </Typography>
                                    {isLoadingUkm || isLoadingUser ? null : (
                                        <Chip
                                            label={
                                                ukmData?.isPro === 1
                                                    ? "PRO"
                                                    : "FREE"
                                            }
                                            size="small"
                                            sx={{
                                                backgroundColor: "#45A779",
                                                color: "#fff",
                                            }}
                                        />
                                    )}
                                </Stack>
                            </Box>
                        </Stack>
                    </Link>
                </Box>
                <List>
                    {user?.aksesTransaksi === 1 && (
                        <ListItem component={Link} to="/transaksi">
                            <ListItemButton
                                selected={location.pathname === "/transaksi"}
                                sx={{ borderRadius: 2 }}
                            >
                                <ListItemIcon
                                    sx={{ minWidth: "unset", marginRight: 2 }}
                                >
                                    <ShoppingBasketIcon
                                        sx={{ width: 32, height: 32 }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        color: "#2D312F",
                                        variant: "h6",
                                        fontSize: 16,
                                    }}
                                    primary="Penjualan"
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {user?.aksesStok === 1 && (
                        <ListItem component={Link} to="/stok">
                            <ListItemButton
                                selected={location.pathname === "/stok"}
                                sx={{ borderRadius: 2 }}
                            >
                                <ListItemIcon
                                    sx={{ minWidth: "unset", marginRight: 2 }}
                                >
                                    <Icon path={mdiPackageVariant} size={1.3} />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        color: "#2D312F",
                                        variant: "h6",
                                        fontSize: 16,
                                    }}
                                    primary="Manajemen Stok"
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {user?.aksesLaporan === 1 && (
                        <ListItem component={Link} to="/laporan">
                            <ListItemButton
                                selected={location.pathname === "/laporan"}
                                sx={{ borderRadius: 2 }}
                            >
                                <ListItemIcon
                                    sx={{ minWidth: "unset", marginRight: 2 }}
                                >
                                    <Icon path={mdiFileChart} size={1.3} />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        color: "#2D312F",
                                        variant: "h6",
                                        fontSize: 16,
                                    }}
                                    primary="Laporan"
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                flexGrow={1}
            />
            <Stack alignItems="center" spacing={2} padding={2} width="100%">
                <Button
                    href={`https://wa.me/send?phone=${noWaAdmin?.nomor}&text=Saya%20ingin%20bertanya%20mengenai%20ULO%20Tumbuh%20saya.`}
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    startIcon={<WhatsApp />}
                    color="whatsapp"
                    fullWidth
                    sx={{ textTransform: "none" }}
                >
                    Hubungi Kami
                </Button>
                <Typography
                    component="div"
                    display="inline-block"
                    color="#7C8883"
                >
                    <MaterialLink
                        target="_blank"
                        href="https://nore.web.id/"
                        underline="hover"
                        display="inline"
                        color="#7C8883"
                    >
                        Nore Inovasi © {thisYear}.
                    </MaterialLink>
                    <Typography display="inline">
                        {" "}
                        All rights reserved
                    </Typography>
                </Typography>
            </Stack>
        </Stack>
    );

    const container =
        window !== undefined ? () => window.document.body : undefined;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryUkmId = urlParams.get("redirect");

    // if (isLoadingUkm || isLoadingUser) {
    //     return (
    //         <Box position="relative" height="100vh">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    return (
        <Box
            sx={{
                display: !!queryUkmId ? "none" : "flex",
                paddingTop: isPhoneScreen
                    ? "56px"
                    : isLaptopScreen
                    ? undefined
                    : "64px",
            }}
        >
            <AppBar
                sx={{
                    display: { xs: "block", lg: "none" },
                    width: { lg: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {ukmData?.namaToko}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={() => setMobileOpen(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", lg: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {laptopDrawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "block",
                        },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {laptopDrawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <AlertSnackbar
                    open={isOpenAlert}
                    onClose={handleCloseAlert}
                    words={words}
                />
                <ModalKonfirmasiLogout />
                {ukmData &&
                    ukmIsDueExpired &&
                    ukmIsDueExpired.totalElements > 0 &&
                    ukmIsDueExpired.content
                        .filter((list) => list.id === ukmData.id)
                        .map((item, idx) => {
                            return (
                                <Box
                                    key={item.id}
                                    display="flex"
                                    justifyContent="center"
                                    padding={1}
                                    sx={{
                                        bgcolor: "rgba(229, 40, 40, 0.1)",
                                        border: "1px solid rgba(229, 40, 40, 1)",
                                        // height: "48.75px",
                                    }}
                                >
                                    {moment(time?.datetime).isAfter(
                                        item.pembayaranBerikutnya,
                                    ) ? (
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Typography
                                                component="div"
                                                color="#6D6D6D"
                                                variant="body2"
                                            >
                                                Langganan <b>{item.namaToko}</b>{" "}
                                                sudah lewat jatuh tempo{" "}
                                                <Box
                                                    display="inline"
                                                    fontWeight="bold"
                                                    color="#E52828"
                                                >
                                                    {item.pembayaranBerikutnya ===
                                                    null
                                                        ? ""
                                                        : new Date(
                                                              item?.pembayaranBerikutnya,
                                                          ).toLocaleString(
                                                              "id",
                                                              {
                                                                  day: "numeric",
                                                                  month: "long",
                                                                  year: "numeric",
                                                              },
                                                          )}
                                                </Box>
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="buttonred"
                                                disableElevation
                                                onClick={() =>
                                                    setIsOpenModalAlertPro(true)
                                                }
                                                sx={{
                                                    minWidth: 130,
                                                    maxWidth: 130,
                                                }}
                                            >
                                                Upgrade ke PRO
                                            </Button>
                                        </Stack>
                                    ) : (
                                        <Typography color="rgba(229, 40, 40, 1)">
                                            Langganan <b>{item.namaToko}</b>{" "}
                                            mendekati jatuh tempo! Segera
                                            perbarui sebelum tanggal{" "}
                                            <b>
                                                {item.pembayaranBerikutnya ===
                                                null
                                                    ? ""
                                                    : new Date(
                                                          item?.pembayaranBerikutnya,
                                                      ).toLocaleString("id", {
                                                          day: "numeric",
                                                          month: "long",
                                                          year: "numeric",
                                                      })}
                                            </b>
                                            .
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                {ukmData && ukmData.isTrial !== 1 && ukmData?.isPro !== 1 && (
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        padding={1}
                        sx={{
                            bgcolor: "rgba(69, 167, 121, 0.1)",
                            border: "1px solid rgba(69, 167, 121, 1)",
                            // height: "48.75px",
                        }}
                    >
                        <Typography color="rgba(69, 167, 121, 1)">
                            Uji coba gratis selama 7 hari untuk mencoba
                            fitur-fitur PRO UKM Lokal
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            color="buttonulo"
                            disableElevation
                            onClick={openModalTrial}
                            sx={{ minWidth: 100, maxWidth: 100 }}
                        >
                            Free Trial
                        </Button>
                    </Stack>
                )}
                {ukmData &&
                    ukmData.isTrial === 1 &&
                    ukmData?.isPro === 1 &&
                    ukmData.trialTempo && (
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                            padding={1}
                            sx={{
                                bgcolor: "rgba(69, 167, 121, 0.1)",
                                border: "1px solid rgba(69, 167, 121, 1)",
                                // height: "48.75px",
                            }}
                        >
                            <Typography
                                component="div"
                                color="rgba(69, 167, 121, 1)"
                            >
                                Waktu trial anda akan habis dalam:{" "}
                                <CountdownComponent expiredTime={expiredTime} />
                                <Typography
                                    display="inline"
                                    color="rgba(69, 167, 121, 1)"
                                >
                                    {" "}
                                    (sampai{" "}
                                    {moment(ukmData.trialTempo).format("LL")})
                                </Typography>
                            </Typography>
                        </Stack>
                    )}
                {ukmData && ukmData.isTrial === 1 && ukmData?.isPro !== 1 && (
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                        padding={1}
                        sx={{
                            bgcolor: "rgba(69, 167, 121, 0.1)",
                            border: "1px solid rgba(69, 167, 121, 1)",
                            // height: "48.75px",
                        }}
                    >
                        <Typography color="rgba(69, 167, 121, 1)">
                            Waktu trial anda sudah habis! Segera upgrade untuk
                            mendapatkan fitur-fitur PRO kembali!
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            color="buttonulo"
                            disableElevation
                            onClick={() => setIsOpenModalAlertPro(true)}
                            sx={{ minWidth: 130, maxWidth: 130 }}
                        >
                            Upgrade ke PRO
                        </Button>
                    </Stack>
                )}
                <Backdrop
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isLoadingUser || isLoadingUkm}
                >
                    <img
                        src={logoUloGif}
                        height="100px"
                        width="100px"
                        style={{ objectFit: "contain" }}
                        alt="loading..."
                    />
                    <Typography color="#fff">Memuat Data UKM...</Typography>
                </Backdrop>
                <Container maxWidth="xl">{children}</Container>
            </Box>
            {ukmData && (
                <ModalTrial
                    closeModalTrial={closeModalTrial}
                    isOpenModalTrial={isOpenModalTrial}
                    ukmData={ukmData}
                />
            )}
            {ukmData && (
                <ModalAlertPro
                    isOpenModalAlertPro={isOpenModalAlertPro}
                    closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                    dataUkm={{ id: ukmData?.id, nama: ukmData?.namaToko }}
                />
            )}
        </Box>
    );
}
