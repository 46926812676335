import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import {
    DaftarItemListInvoice,
    DaftarKategoriListInvoice,
    ParamsGetKategoriListInvoice,
    TipeStruk,
} from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import toRupiah from "../../utils/toRupiah";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Tooltip } from "@mui/material";
import truncate from "../../utils/truncate";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import { Close } from "@mui/icons-material";
import toRibuan from "../../utils/toRibuan";

interface IModalDetailInvoiceProps {
    id: number;
    fullScreen: boolean;
    isOpenModalDetailInvoice: boolean;
    statusDetailInvoice: "idle" | "error" | "loading" | "success";
    closeModalDetailInvoice: () => void;
    tanggalTransaksi: string;
    nomorInvoice: string;
    daftarItem: DaftarItemListInvoice[];
    daftarKategori: DaftarKategoriListInvoice[];
    totalHarga: number;
    diskon: number;
    pajak: number;
    pajakPersen: number;
    totalPembayaran: number;
    uangYangDibayar: number;
    kembalian: number;
    kasir: string;
    sales: string | null;
    namaMetode: string | null;
    keterangan: string | null;
    encrypt: string | null;
    namaPemegang: string | null;
    nomorTujuan: string | null;
    qr?: string | null;
    transaksiKe: string;
    paramsKategori: ParamsGetKategoriListInvoice;
    setParamsKategori: (params: ParamsGetKategoriListInvoice) => void;
    refetchDetailInvoice: () => void;
}

const ModalDetailInvoice = ({
    id,
    fullScreen,
    isOpenModalDetailInvoice,
    statusDetailInvoice,
    closeModalDetailInvoice,
    tanggalTransaksi,
    nomorInvoice,
    daftarItem,
    daftarKategori,
    totalHarga,
    diskon,
    pajak,
    pajakPersen,
    totalPembayaran,
    uangYangDibayar,
    kembalian,
    kasir,
    sales,
    namaMetode,
    keterangan,
    encrypt,
    namaPemegang,
    nomorTujuan,
    qr,
    transaksiKe,
    paramsKategori,
    setParamsKategori,
    refetchDetailInvoice,
}: IModalDetailInvoiceProps) => {
    const { data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const newCart = daftarItem.map((items, index) => ({
        id: index,
        namaBarang: String(items.namaItem),
        qty: Number(items.jumlahItem),
        harga: Number(items.hargaSatuan),
        namaKategori: String(items.namaKategori),
    }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukLaporan") ?? TipeStruk.Mobile,
    );

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukLaporan", newTipeStruk);
        }
    };

    const [kategoriParams, setKategoriParams] =
        useState<ParamsGetKategoriListInvoice>(
            paramsKategori || { grupProduk: 0 },
        );

    const { data: detailInvoice } = useDetailInvoice(
        Number(id),
        kategoriParams,
    );

    const handleCheckboxKategori = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const newParams: ParamsGetKategoriListInvoice = {
            grupProduk: event.target.checked ? 1 : 0,
        };
        setKategoriParams(newParams);
        setParamsKategori(newParams);
    };

    useEffect(() => {
        refetchDetailInvoice();
    }, [kategoriParams, refetchDetailInvoice]);

    const groupByKategori = false;

    return (
        <Dialog
            maxWidth="md"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailInvoice && statusDetailInvoice === "success"}
            onClose={() => {
                setKirimEmail(false);
                setEmail("");
                closeModalDetailInvoice();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                >
                    <Typography fontSize={32} fontWeight="bold">
                        Detail Invoice
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Typography color="#7c8883">Tanggal</Typography>
                        <Typography color="#464e4b">
                            {tanggalTransaksi}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography color="#7c8883">No. Invoice</Typography>
                        <Typography color="#464e4b">{nomorInvoice}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography color="#7c8883">Jenis Transaksi</Typography>
                        <Typography color="#464e4b">TODO</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={6} md={4}>
                        <Typography color="#7c8883">
                            Metode Pembayaran
                        </Typography>
                        <Typography color="#464e4b">
                            {namaMetode ?? "Tunai"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography color="#7c8883">Nama Kasir</Typography>
                        <Typography color="#464e4b">{kasir}</Typography>
                    </Grid>
                    {sales && (
                        <Grid item xs={6} md={4}>
                            <Typography color="#7c8883">Sales</Typography>
                            <Typography color="#464e4b">{sales}</Typography>
                        </Grid>
                    )}
                </Grid>

                <Box my={2}>
                    <Divider sx={{ borderStyle: "dashed" }} />
                </Box>

                <Stack
                    spacing={1}
                    p={2}
                    border="1px solid #a8b4af"
                    borderRadius="8px"
                >
                    <Typography color="#2d312f" fontSize={20} fontWeight={500}>
                        Daftar Produk
                    </Typography>
                    {daftarItem.map((items, index) => (
                        <Stack direction="row" key={index}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Tooltip title={items.namaItem}>
                                        <Typography color="#464e4b">
                                            {truncate(items.namaItem, 20)}
                                        </Typography>
                                    </Tooltip>
                                    <Typography color="#7c8883">
                                        @{toRupiah(items.hargaSatuan)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        color="#464e4b"
                                        fontWeight={500}
                                    >
                                        {items.jumlahItem}x
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        color="#464e4b"
                                        fontWeight={500}
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            items.hargaSatuan *
                                                items.jumlahItem,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    ))}
                </Stack>
                <Stack
                    spacing={1}
                    p={2}
                    mt={2}
                    border="1px solid #a8b4af"
                    borderRadius="8px"
                >
                    <Typography color="#2d312f" fontSize={20} fontWeight={500}>
                        Ringkasan Pembayaran
                    </Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color="#464e4b">
                                Subtotal Harga
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography color="#7c8883" fontWeight={500}>
                                    Rp.
                                </Typography>
                                <Typography color="#7c8883" fontWeight={500}>
                                    {toRibuan(totalHarga)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={4} md={5} lg={5}>
                            <Typography color="#464e4b">
                                {pajakPersen
                                    ? `Pajak (${pajakPersen}%)`
                                    : `Pajak`}
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                bgcolor="#e9f2f0"
                                paddingX={1}
                            >
                                <Typography color="#7c8883" fontWeight={500}>
                                    Rp.
                                </Typography>
                                <Typography color="#7c8883" fontWeight={500}>
                                    {toRibuan(pajak)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={4} md={5} lg={5}>
                            <Typography color="#464e4b">Diskon</Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography color="#ed4521" fontWeight={500}>
                                    Rp.
                                </Typography>
                                <Typography color="#ed4521" fontWeight={500}>
                                    {toRibuan(diskon ?? 0)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderStyle: "dashed", paddingTop: 1 }} />
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color="#464e4b">
                                Total Tagihan
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography
                                    color="#2d312f"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color="#2d312f"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    {toRibuan(totalPembayaran)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color="#464e4b">
                                Uang yang Dibayar
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                bgcolor="#e9f2f0"
                                paddingX={1}
                            >
                                <Typography
                                    color="#2d312f"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color="#0a5bbb"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    {toRibuan(uangYangDibayar)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color="#464e4b">Kembalian</Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography
                                    color="#2d312f"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color="#119c5b"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    {toRibuan(kembalian)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {keterangan && (
                        <>
                            <Divider
                                sx={{ borderStyle: "dashed", marginTop: 1 }}
                            />
                            <Box mt={1}>
                                <Typography color="#464e4b">
                                    Keterangan:
                                </Typography>
                                <Typography color="#464e4b">
                                    {keterangan}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Stack>
                <Stack direction="row" spacing={2} mt={2} alignItems="center">
                    <Typography>Pilih Tipe Struk</Typography>
                    <ToggleButtonGroup
                        value={tipeStruk}
                        exclusive
                        size="small"
                        onChange={handleTipeStruk}
                    >
                        <ToggleButton
                            value={TipeStruk.Mobile}
                            sx={{
                                display: "flex",
                                paddingX: 2,
                            }}
                        >
                            <b>A8</b>
                        </ToggleButton>
                        <ToggleButton
                            value={TipeStruk.PC}
                            sx={{
                                display: "flex",
                                paddingX: 2,
                            }}
                        >
                            <b>A4</b>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Box display="flex">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={kirimEmail}
                                onChange={(_, checked) => {
                                    handleKirimEmail(checked);
                                }}
                            />
                        }
                        label="Kirim struk"
                        sx={{
                            height: "fit-content",
                        }}
                    />
                </Box>
                {kirimEmail && (
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        marginBottom={2}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={email}
                                size="small"
                                type="email"
                                fullWidth
                                placeholder="8316352725"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +62
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => setEmail(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={email.length <= 0}
                                variant="outlined"
                                onClick={() => {
                                    setKirimEmail(false);
                                    setEmail("");
                                    closeModalDetailInvoice();
                                }}
                                href={`https://wa.me/62${email}?text=Struk%20pembayaran%20ULO%20${nomorInvoice}%20${window.location.origin}/struk/${encrypt}`}
                                target="_blank"
                                rel="noreferrer"
                                fullWidth
                            >
                                Kirim struk ke WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Stack direction="row" justifyContent="flex-end" gap={1}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                    >
                        Kembali
                    </Button>
                    <Box>
                        {ukmData &&
                            (tipeStruk === TipeStruk.Mobile ? (
                                <BlobProvider
                                    document={
                                        <PrintStrukPdf
                                            namaToko={ukmData.namaToko}
                                            alamatToko={ukmData.alamatToko}
                                            telpToko={ukmData.noTelp}
                                            gambarToko={
                                                ukmData?.gambarTokoBW === null
                                                    ? undefined
                                                    : ukmData?.gambarTokoBW
                                            }
                                            tanggalTransaksi={tanggalTransaksi}
                                            transaksiKe={transaksiKe}
                                            kasir={kasir}
                                            sales={sales}
                                            total={totalPembayaran}
                                            cart={newCart}
                                            groupByKategori={groupByKategori}
                                            category={daftarKategori}
                                            diskon={diskon}
                                            pajak={pajak}
                                            pajakPersen={pajakPersen}
                                            tunai={uangYangDibayar}
                                            kembalian={kembalian}
                                            isPro={ukmData.isPro}
                                            namaMetode={namaMetode ?? "Tunai"}
                                            html={
                                                findCustomStruk &&
                                                findCustomStruk.html !== null
                                                    ? findCustomStruk?.html
                                                    : undefined
                                            }
                                            keterangan={keterangan}
                                            noMetode={nomorTujuan}
                                        />
                                    }
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="center"
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                                sx={{ height: "100%" }}
                                            >
                                                Print Ulang Struk
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>
                            ) : (
                                <BlobProvider
                                    document={
                                        <PrintStrukA4
                                            ukmData={ukmData}
                                            tanggalTransaksi={tanggalTransaksi}
                                            transaksiKe={transaksiKe}
                                            kasir={kasir}
                                            sales={sales}
                                            total={totalPembayaran}
                                            cart={newCart}
                                            category={daftarKategori}
                                            groupByKategori={groupByKategori}
                                            diskon={diskon}
                                            pajak={pajak}
                                            pajakPersen={pajakPersen}
                                            tunai={uangYangDibayar}
                                            kembalian={kembalian}
                                            namaMetode={namaMetode ?? "Tunai"}
                                            html={
                                                findCustomStruk &&
                                                findCustomStruk.html !== null
                                                    ? findCustomStruk?.html
                                                    : undefined
                                            }
                                            keterangan={keterangan}
                                            namaPemegang={namaPemegang}
                                            nomorTujuan={nomorTujuan}
                                            qr={qr}
                                        />
                                    }
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="center"
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                                sx={{ height: "100%" }}
                                            >
                                                Print Ulang Struk
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>
                            ))}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailInvoice;
