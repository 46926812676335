import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";
import { ModalStokField } from "../constants/types";

type Section = "single" | "bundle";

interface State {
    isOpenModal: boolean;
    dataStok?: ModalStokField;
    openModal: (data?: ModalStokField) => void;
    closeModal: () => void;
    section: Section;
    setSection: React.Dispatch<React.SetStateAction<Section>>;
}

interface IStokModalContext {
    children: ReactNode;
}

const StokModalContext = createContext<State | undefined>(undefined);

const StokModalProvider = ({ children }: IStokModalContext) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [dataStok, setDataStok] = useState<ModalStokField>();
    const [section, setSection] = useState<Section>("single");

    const openModal = useCallback((data?: ModalStokField) => {
        if (data) {
            setDataStok((prev) => ({
                ...prev,
                ...data,
            }));
        }

        setIsOpenModal(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsOpenModal(false);
        setDataStok(undefined);
    }, []);

    const value = useMemo(
        () => ({
            isOpenModal,
            dataStok,
            openModal,
            closeModal,
            section,
            setSection,
        }),
        [isOpenModal, dataStok, openModal, closeModal, section, setSection],
    );

    return (
        <StokModalContext.Provider value={value}>
            {children}
        </StokModalContext.Provider>
    );
};

const useStokModal = () => {
    const context = React.useContext(StokModalContext);
    if (context === undefined) {
        throw new Error("useStokModal must be used within a StokModalProvider");
    }
    return context;
};

export { StokModalContext, StokModalProvider, useStokModal };
